import React, { useState } from "react"
import { graphql } from "gatsby"
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap"
import axios from "axios"
import queryString from "query-string"

import Link from "../UI/Link"
import slugify from "../../utils/slugify"
import {
  sectionClass,
  successSectionClass,
  rowClass,
  formControlClass,
  successContainerClass,
  successMsgClass,
  sendBtnClass,
  leafletQuantityClass,
  checkboxGroupClass,
  checkboxContainerClass,
  checkmarkClass
} from "./LeafletRequestForm.module.scss"

export const fragment = graphql`
  fragment leafletRequestFormFragment on WpPage_Flexlayouts_FlexibleLayouts_LeafletRequestForm {
    instructions
    leafletTypes {
      leaflet
    }
    conditionsLink {
      ... on WpPage {
        link
      }
    }
    privacyLink {
      ... on WpPage {
        link
      }
    }
    submitMessage
    emailTo
  }
`

const LeafletRequestForm = ({
  instructions,
  leafletTypes,
  conditionsLink,
  privacyLink,
  submitMessage,
  emailTo
}) => {
  const [status, setStatus] = useState({ initial: true })
  const [validated, setValidated] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true)

  const validate = event => {
    const form = event.currentTarget

    if (form.checkValidity() === false) {
      setSubmitDisabled(true)
    } else {
      setSubmitDisabled(false)
    }
  }

  const handleSubmit = event => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return
    }
    setValidated(true);
    let leafletList = ""

    for (let key in leafletTypes) {
      if (form.elements.namedItem(slugify(leafletTypes[key].leaflet))) {
        const activity = leafletTypes[key].leaflet.replace(/\-/g, " ")
        leafletList += `${activity} - ${form.elements.namedItem(slugify(leafletTypes[key].leaflet)).value}; `
      }
    }

    leafletList = leafletList.replace(/;\s?$/, ".")

    const data = {
      contactName: form.elements.contactName.value,
      jobtitle: form.elements.jobtitle.value,
      hospital: form.elements.hospital.value,
      department: form.elements.department.value,
      postcode: form.elements.postcode.value,
      email: form.elements.email.value,
      directphone: form.elements.directphone.value,
      workphone: form.elements.workphone.value,
      requestedLeaflets: leafletList,
      comments: form.elements.comments.value,
      emailTo: emailTo
    }

    const postForm = async () => {
      try {
        const response = await axios.post(
          `${process.env.LEAFLET_FORM_URL}`,
          queryString.stringify(data)
        )
        setStatus({
          success: submitMessage,
          initial: false
        })
      } catch (exception) {
        setStatus({
          ...status,
          exception: `${exception.response.status}: ${exception.response.statusText}`
        })
      }
    }
    postForm()
  }

  return (
    <section
      className={status && status.success ? successSectionClass : sectionClass}>
      {status && status.initial && (
        <Container className="py-5 bg-purple">
          <Row className={rowClass}>
            <Col md={10} className="mx-auto">
              <Form
                id="LeafletRequestForm"
                validated={validated}
                onSubmit={handleSubmit}
                method="post">
                <>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="contactName">
                        <Form.Label for="contactName">Name*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="contactName"
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="jobtitle">
                        <Form.Label for="jobtitle">Job title*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="jobtitle"
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="hospital">
                        <Form.Label for="hospital">Hospital*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="hospital"
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="department">
                        <Form.Label for="department">
                          Ward / Department*
                        </Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="department"
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="postcode">
                        <Form.Label for="postcode">Postcode*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="postcode"
                          type="text"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="email">
                        <Form.Label for="email">Email*</Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="email"
                          type="email"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="directphone">
                        <Form.Label for="directphone">
                          Direct phone number
                        </Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="directphone"
                          type="tel"
                          minlength="11"
                        />
                      </Form.Group>
                    </Col>

                    <Col md={6}>
                      <Form.Group controlId="workphone">
                        <Form.Label for="workphone">
                          Work phone number*
                        </Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="workphone"
                          type="tel"
                          minlength="11"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="my-mid4">
                    <Col className="mb-3">
                      <p>{instructions}</p>
                    </Col>
                    {leafletTypes.map((item, index) => {
                      return (
                        <>
                          <Col md={6} key={`leaflet-${index}`}>
                            <Form.Group controlId={slugify(item.leaflet)}>
                              <Row className={`${leafletQuantityClass} mb-2`}>
                                <Col xs={3}>
                                  <Form.Control
                                    type="text"
                                    id={slugify(item.leaflet)}
                                    name={slugify(item.leaflet)}
                                    pattern="[yY0-9]{0,4}"
                                  />
                                </Col>
                                <Col
                                  xs={9}
                                  className={`d-flex align-items-center ps-0`}>
                                  <label htmlFor={slugify(item.leaflet)}>
                                    {item.leaflet}
                                  </label>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Col>
                        </>
                      )
                    })}
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="comments">
                        <Form.Label for="comments">
                          Additional comments:
                        </Form.Label>
                        <Form.Control
                          className={formControlClass}
                          name="comments"
                          as="textarea"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-between align-items-center mt-4">
                    <Col md={6} lg={7}>
                      <div
                        className={`${checkboxGroupClass} form-group form-check`}>
                        <label
                          className={`${checkboxContainerClass} form-check-label text-start`}
                          htmlFor="terms">
                          <input
                            type="checkbox"
                            className={`form-check-input`}
                            id="terms"
                            name="terms"
                            required
                          />
                          <span className={checkmarkClass} />I agree to the EPT{" "}
                          <Link
                            classes="linkClass"
                            link={privacyLink?.link}
                            title="Privacy Policy"
                          />
                          {conditionsLink && (
                            <>
                              {" and "}
                              <Link
                                classes="linkClass"
                                link={conditionsLink?.link}
                                title="Terms"
                              />
                            </>
                          )}
                          {"."}
                        </label>
                      </div>
                    </Col>
                    <Col md={6} lg={5}>
                      <Form.Group className="text-sm-end">
                        <Button type="submit" className={sendBtnClass}>
                          Submit
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              </Form>
            </Col>
          </Row>
        </Container>
      )}
      {status && status.success && (
        <Container className={successContainerClass}>
          <div className={successMsgClass}>{status.success}</div>
        </Container>
      )}
      {status && status.exception && (
        <Alert variant="danger" className="my-5">
          <Alert.Heading>{status.exception}</Alert.Heading>
        </Alert>
      )}
    </section>
  )
}

export default LeafletRequestForm
